export default function CodeSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="527"
      height="219"
      viewBox="0 0 1058 434.74542"
      xlink="http://www.w3.org/1999/xlink"
    >
      <polygon
        points="643.457 221.721 705.39 159.977 643.457 98.233 662.339 79.414 743.154 159.977 662.339 240.54 643.457 221.721 643.457 221.721"
        fill="#e6e6e6"
        style={{ isolation: "isolate" }}
      />
      <rect
        x="466.59505"
        y="135.20679"
        width="25.176"
        height="25.17601"
        fill="#e6e6e6"
        style={{ isolation: "isolate" }}
      />
      <rect
        x="567.80264"
        y="135.20679"
        width="25.17601"
        height="25.17601"
        fill="#e6e6e6"
        style={{ isolation: "isolate" }}
      />
      <rect
        x="517.19884"
        y="135.20679"
        width="25.17601"
        height="25.17601"
        fill="#e6e6e6"
        style={{ isolation: "isolate" }}
      />
      <polygon
        points="432.482 221.092 370.486 159.348 432.419 97.604 413.537 78.785 332.785 159.348 413.6 239.911 432.482 221.092"
        fill="#e6e6e6"
        style={{ isolation: "isolate" }}
      />
      <polygon
        points="643.457 209.763 705.39 148.019 643.457 86.274 662.339 67.455 743.154 148.019 662.339 228.582 643.457 209.763 643.457 209.763"
        fill="#6c63ff"
      />
      <path
        d="M537.59506,385.05149h25.176v-25.176H537.595Zm126.38359-25.176h-25.176v25.176h25.176Zm-75.77981,25.176h25.176v-25.176h-25.176Z"
        transform="translate(-71 -232.62729)"
        fill="#6c63ff"
      />
      <polygon
        points="432.482 209.133 370.486 147.389 432.419 85.645 413.537 66.826 332.785 147.389 413.6 227.952 432.482 209.133"
        fill="#6c63ff"
      />
      <polygon
        points="129.305 410.449 143.399 413.856 163.248 361.117 142.447 356.088 129.305 410.449"
        fill="#a0616a"
      />
      <path
        d="M193.63206,655.732l4.3279-17.902,27.57443,6.66619a18.03222,18.03222,0,0,1,13.27516,21.74027l-.09544.394Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <polygon
        points="191.696 420.05 206.196 420.049 213.093 364.122 191.694 364.123 191.696 420.05"
        fill="#a0616a"
      />
      <path
        d="M259.18336,648.12878l28.36885-.00118h.00079A18.01192,18.01192,0,0,1,305.565,666.139v.40553l-46.3808.002Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <path
        d="M259.29356,633.32467l2.38386-87.00733-3.21111-35.28086-6.26571,27.151-27.37942,89.28493L201.21793,621.261l27.33582-96.27019-5.93712-61.74814,17.05775-10.96528.23013.04158,58.27861,10.74237L284.10491,630.8438Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <path
        d="M282.35859,475.17467c-4.69322,0-10.18192-1.51185-16.29171-5.82451-19.551-13.79842-42.86266,1.04525-43.09625,1.19539l-.9465.6156.03321-1.1284c.02367-.80386,2.36509-80.71159,2.36509-106.42468,0-26.56546,17.33205-45.962,22.64433-51.27373,5.21382-5.21468,9.601-5.09919,15.15465-4.95482.95689.02426,1.95449.05082,3.00378.05082,7.25755,0,14.31354,6.97255,14.61037,7.26938l.14177.14206.02628.19866,20.13863,151.63135-.15419.19519C299.89479,466.98594,293.28172,475.17467,282.35859,475.17467Z"
        transform="translate(-71 -232.62729)"
        fill="#ccc"
      />
      <circle
        cx="268.30764"
        cy="269.43064"
        r="29.04805"
        transform="translate(-167.80192 142.99595) rotate(-61.3368)"
        fill="#a0616a"
      />
      <path
        d="M312.77524,499.45367a11.89282,11.89282,0,0,1-.69-18.22321L295.589,442.31962l21.5994,3.97529,12.35367,36.34094a11.95731,11.95731,0,0,1-16.76682,16.81782Z"
        transform="translate(-71 -232.62729)"
        fill="#a0616a"
      />
      <path
        d="M308.10258,477.30444l-29.80746-60.80685-13.06993-45.76567.0104-39.75752c.02945-.15708,3.102-15.62212,14.49025-14.55262,10.32658,1.0002,15.88834,6.04973,18.597,16.8845,2.301,9.204-.846,36.50975-1.15064,39.08649,1.9981,4.73768,31.97592,76.16447,27.013,84.76553a2.18414,2.18414,0,0,1-.78451.8131l5.312,11.21476Z"
        transform="translate(-71 -232.62729)"
        fill="#ccc"
      />
      <path
        d="M264.59343,280.3744c3.48214.45308,6.10882-3.10969,7.32709-6.40307s2.14653-7.12755,5.174-8.90657c4.13617-2.43049,9.42822.49275,14.15814-.30915,5.34156-.90559,8.81456-6.56659,9.08675-11.97752s-1.8811-10.61511-3.99389-15.60394l-.7376,6.2a12.295,12.295,0,0,0-5.37281-10.74684l.9507,9.09752a9.65567,9.65567,0,0,0-11.10838-7.9897l.14973,5.42064c-6.1695-.73362-12.39291-1.46827-18.58067-.90936s-12.41874,2.51667-17.10855,6.59179c-7.01521,6.09574-9.57732,16.1331-8.71716,25.38683s4.68008,17.94693,8.6613,26.34462c1.00168,2.11288,2.38715,4.49712,4.7099,4.76627,2.087.24183,3.99666-1.50276,4.64544-3.50106a12.69016,12.69016,0,0,0-.05633-6.234c-.587-3.1191-1.32688-6.30722-.775-9.43271s2.80033-6.21408,5.951-6.59711,6.37453,3.21831,4.85976,6.00735Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <path
        d="M983.59122,495.58011a10.05576,10.05576,0,0,1,2.3586-15.23787l-7.4341-34.953,17.28994,6.77433,4.40882,32.1534a10.11027,10.11027,0,0,1-16.62326,11.26316Z"
        transform="translate(-71 -232.62729)"
        fill="#ffb8b8"
      />
      <path
        d="M981.62765,468.308l-.09277-.34339c-9.34669-34.5709-19.00838-70.30806-30.8041-109.21933l-.07738-.2549.26022-.15181c6.57463-3.83653,16.45658-3.58105,22.986.59367a17.15009,17.15009,0,0,1,7.44359,18.19132l8.60322,44.85729c3.49772,14.663,7.11763,29.84049,10.57731,44.80585l.09811.42426-.53579.03421c-6.29931.40058-12.80735.81488-18.02141,1.0436Z"
        transform="translate(-71 -232.62729)"
        fill="#ccc"
      />
      <polygon
        points="831.194 421.744 818.934 421.744 813.1 374.457 831.194 374.457 831.194 421.744"
        fill="#ffb8b8"
      />
      <path
        d="M905.32117,666.25558H865.79041v-.5a15.40421,15.40421,0,0,1,15.387-15.38672h24.1438Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <polygon
        points="946.719 411.631 935.037 415.353 915.121 372.069 932.362 366.575 946.719 411.631"
        fill="#ffb8b8"
      />
      <path
        d="M986.64124,666.63351l-.15162-.47657a15.40435,15.40435,0,0,1,9.989-19.332l23.00415-7.33008,4.82324,15.13672Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <circle cx="884.66704" cy="69.87633" r="24.56103" fill="#ffb8b8" />
      <path
        d="M966.69432,297.95159c.1396-9.90634-4.00632-19.71729-10.41658-24.64995a16.64876,16.64876,0,0,0-21.13211.37344c-6.311,5.15746-10.26257,15.11151-9.92822,25.00948C939.35813,298.7248,953.49885,298.765,966.69432,297.95159Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <path
        d="M909.19348,639.15011l-.4917-.04785c-10.66577-1.04688-21.69482-2.12793-32.11792-2.252l-.56689-.00683.07739-.56153c2.46948-17.88574,3.51636-36.47949,4.52881-54.46191.99976-17.75586,2.0332-36.11572,4.44092-53.55322,1.01855-4.66944,2.01123-9.28321,3.00049-13.88037,8.18554-38.043,15.91723-73.97657,31.8767-109.54639l.15088-.33594.36548.04395c14.97437,1.812,29.36328,7.27539,40.51538,15.3833l.21607.15674-.0105.26709c-.21509,5.5249-.43774,11.23632-.10815,16.439a55.797,55.797,0,0,1,7.95385,49.2832c4.39112,14.6753,8.93091,29.84522,13.563,44.80957,11.24854,30.83838,22.88818,62.75147,34.81983,95.10108l.1958.53027-.55054.12891c-8.58472,2.01562-17.44043,4.09375-26.17676,4.90039l-.32983.03027-.15674-.292A712.85141,712.85141,0,0,1,936.30042,501.2297c-2.98145,9.09228-7.27637,17.29931-11.43726,25.249A189.02218,189.02218,0,0,0,914.381,548.88058c-1.84155,15.12109-2.33008,31.5039-2.802,47.34668-.44653,14.97949-.86816,29.12793-2.3313,42.43164Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <path
        d="M947.28183,273.756c10.6461-2.29372,22.42205-.11154,30.78278,7.07137s12.60642,19.60911,9.17169,30.26612-15.09593,18.15524-25.6397,15.40318c3.02034-8.51233-.37868-18.96349-7.76211-23.86665C945.07836,296.81524,942.66781,282.93292,947.28183,273.756Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <path
        d="M960.2074,420.99923l-.38941-.17725c-13.58129-6.19629-26.89843-10.95068-39.37573-15.28857l-.38257-.08252-.001-1.10938.24829.08643c.05224-.11523.11108-.24609.17016-.37646.17041-.377.28662-.63379.3855-.80372l-.00268-.00146c1.04028-2.11963,1.86718-4.168,2.66674-6.14893.8252-2.04443,1.677-4.15527,2.77759-6.38867a19.35575,19.35575,0,0,1-4.27637-20.82861c2.75635-6.53272,9.78418-11.62061,17.16846-12.46485,2.70581-7.44873,8.73487-23.10009,8.73487-23.10009s12.67822-.34815,17.77685-.99512l.08569-.01074.08423.01855c11.13086,2.439,20.42359,12.97168,22.5979,25.61377,1.83545,10.66992-.95166,22.87451-7.84741,34.36572a148.03962,148.03962,0,0,1-11.70947,16.08887c-3.106,3.88379-6.03955,7.55176-8.47608,11.2461Z"
        transform="translate(-71 -232.62729)"
        fill="#ccc"
      />
      <path
        d="M984.29217,290.49254v0Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <path
        d="M984.7908,288.31066c-7.45248-1.11243-13.30382-8.73046-12.45669-16.21774s8.2526-13.60541,15.76524-13.02465,13.88983,7.76435,13.57617,15.29287S994.306,289.731,984.7908,288.31066Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <path
        d="M983.62732,283.45577c-7.45239-1.11242-13.30371-8.73047-12.45654-16.21771a15.24139,15.24139,0,0,1,8.88183-11.73438c-6.01,1.31543-11.18237,6.55249-11.88183,12.73438-.84717,7.48724,5.00415,15.10529,12.45654,16.21771a14.20792,14.20792,0,0,0,8.17188-1.14294A14.50427,14.50427,0,0,1,983.62732,283.45577Z"
        transform="translate(-71 -232.62729)"
        fill="#2f2e41"
      />
      <path
        d="M839.06875,415.43349a10.05575,10.05575,0,0,1,15.15306-2.85319l30.47891-18.65516-.6265,18.55912-28.84725,14.87a10.11028,10.11028,0,0,1-16.15822-11.92072Z"
        transform="translate(-71 -232.62729)"
        fill="#ffb8b8"
      />
      <path
        d="M864.12832,404.49546l.29286-.20188c29.48139-20.33114,59.95745-41.34787,92.71522-65.43426l.21456-.15789.22983.19477c5.80788,4.92069,8.85952,14.32312,7.09886,21.87045A17.15011,17.15011,0,0,1,950.00786,373.846l-39.42775,23.05743c-12.65982,8.18336-25.764,16.65334-38.7215,24.90159l-.36733.23385-.21077-.49378c-2.47653-5.8059-5.03555-11.80405-6.98845-16.644Z"
        transform="translate(-71 -232.62729)"
        fill="#ccc"
      />
      <path
        d="M453,667.37271H72a1,1,0,0,1,0-2H453a1,1,0,1,1,0,2Z"
        transform="translate(-71 -232.62729)"
        fill="#3f3d56"
      />
      <path
        d="M1128,667.37271H747a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z"
        transform="translate(-71 -232.62729)"
        fill="#3f3d56"
      />
    </svg>
  );
}
