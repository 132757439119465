import CodeSVG from "./CodeSVG";
import DOMPurify from "dompurify";
import MailChimpI from "./mailchimp";

export default function App() {
  return (
    <div
      style={{
        minHeight: "100vh",
        maxWidth: "100%",
        backgroundColor: "#F1F1F1",
      }}
    >
      <div
        style={{
          height: "40vh",
          maxWidth: "50%",
          display: "flex",
          justifyContent: "center",

          marginLeft: "25%",
        }}
      >
        <CodeSVG />
      </div>
      <div
        style={{
          textAlign: "center",
          fontFamily: "Calibri",
        }}
      >
        <h1 style={{ fontFamily: "Helvetica" }}>
          Wir sind bald wieder für Euch da
        </h1>
        <h1 style={{ fontFamily: "Helvetica" }}>
          Schreibt Euch ein, um nichts zu verpassen!
        </h1>
      </div>
      <br />
      <br />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <MailChimpI />
      </div>
      <br />
    </div>
  );
}
